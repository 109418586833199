<!-- 修改 昵称 / 姓名 / 邮箱 公共页面 -->
<template>
  <div class="do-page-setbaseinfo">
    <base-nav-bar
      @click-left="router.replace('/my/personal-center')"
      left-arrow
      :title="navbarTitle[$route.params.id]"
    >
      <div
        class="right-text"
        @click="setUserInfo"
        :style="{
          color: checkoutParames ? '#0057FF' : 'rgba(3,5,15,0.45)',
        }"
      >
        <van-loading v-if="loading" type="spinner" size="20" color="#1989fa" />
        <span v-else>完成</span>
      </div>
    </base-nav-bar>
    <base-bscroll :safeareaNavbar="true" :options="{ bounce: true }">
      <div class="do-page-setbaseinfo-content">
        <van-field
          class="do-com-input"
          :clearable="true"
          v-model="input"
          :placeholder="placeholderCotnent[$route.params.id]"
        />
      </div>
    </base-bscroll>
  </div>
</template>
<script setup>
import { useRouter, useRoute } from "vue-router";
import { ref, computed } from "vue";
import { sendAccountInfo } from "@/axios/user";
import { Toast } from "vant";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();
const route = useRoute();

const input = ref(store.state.user.info[route.params.id] || "");
const loading = ref(false);

const navbarTitle = {
  nickname: "昵称",
  name: "姓名",
  mail: "邮箱",
};
const placeholderCotnent = {
  nickname: "请输入昵称",
  name: "请输入姓名",
  mail: "请输入邮箱账号",
};

const checkoutParames = computed(() => {
  if (route.params.id !== "mail" && input.value.trim().length > 0) {
    return true;
  } else if (
    route.params.id === "mail" &&
    input.value.trim().length > 0 &&
    input.value.match(/^\w+@\w+\.\w+$/i)
  ) {
    return true;
  }
  return false;
});
async function setUserInfo() {
  if (loading.value || !checkoutParames.value) return;
  try {
    // 登录类型，写死 1
    loading.value = true;
    await sendAccountInfo({
      [route.params.id]: input.value,
    });
    await store.dispatch("getAccountInfo");
    loading.value = false;
    router.replace("/my/personal-center");
  } catch (error) {
    if (error) {
      Toast({
        message: error.msg || "登陆失败，未知错误",
      });
    }
  }
  setTimeout(() => {
    loading.value = false;
  }, 200);
}
</script>

<style lang="scss">
.do-page-setbaseinfo {
  &-content {
    padding: 0.24rem 0.24rem 0 0.24rem;
  }
  .do-com-scroll-wrapper {
    background: $col_bg;
  }
  .right-text {
    font-size: 0.28rem;
    font-weight: 400;
    color: rgba(3, 5, 15, 0.45);
    transition: all 0.3s;
  }
}
</style>
